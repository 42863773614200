<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true">
        <el-form-item label="商品ID" prop="id">
          <el-input
            style="width: 180px"
            v-model="id"
            clearable
            placeholder="请输入商品ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="name">
          <el-input
            style="width: 180px"
            v-model="name"
            clearable
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" icon="el-icon-search" @click="searchinfo"
            >搜索</el-button
          >
          <el-button type="primary" @click="add('1')">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <page-table
      ref="dataTable"
      :data="goodsList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" align="center">
      </el-table-column>
      <el-table-column prop="name" label="名称" align="center">
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center">
      </el-table-column>
      <el-table-column prop="brief" label="简介" align="center">
      </el-table-column>
      <el-table-column prop="goods_img" label="封面图" align="center" width="200">
        <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px"
            :src="scope.row.goods_img" 
            :preview-src-list="[scope.row.goods_img]">
            </el-image>
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" width="150">
        <template slot-scope="scope">
          <el-link type="success" v-if="scope.row.type == '1'"
            >普通商品</el-link
          >
          <el-link type="danger" v-if="scope.row.type == '2'"
            >兑换券</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="是否上架" align="center">
        <template slot-scope="scope">
          <el-link type="success" v-if="scope.row.is_show == 10">上架</el-link>
          <el-link type="danger" v-if="scope.row.is_show  == 20">下架</el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        align="center"
        width="150"
      >
    </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-link
            type="primary"
            @click="showtable(scope.row)"
            style="margin-left: 10px"
            >查看</el-link
          > -->
          <el-link
            type="success"
            style="margin-left: 10px"
            @click="edit(2, scope.row)"
            >编辑</el-link
          >
        </template>
      </el-table-column>
    </page-table>
    <el-dialog
      title="商品详情"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="close"
    >
        <div v-html="richText"></div>
    </el-dialog>
    <add-Dialog ref="AddDialog"></add-Dialog>
  </div>
</template>

<script>
import { mallGoodsIndex} from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import pageTable from "@/components/pageTable.vue";
import addDialog from "./components/addDialog.vue"
export default {
  name: "user",
  components: {
    pageTable,
    addDialog,
  },
  data() {
    return {
      id:"",
      name:"",
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
      goodsList:[],
      dialogVisible:false,
      richText:""
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
  },
  created() {
    this.getUserList(); //获取用户列表
    this.token = sessionStorage.getItem("token");
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.dialogVisible = false;
    },
    setData(data) {
      data.map((item) => {
        item["value"] = item.code;
        item["label"] = item.name;
        if (item.children) {
          this.setData(item.children);
        }
      });
    },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    searchinfo() {
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        id:this.id,
        name:this.name
      };
      mallGoodsIndex(params).then((res) => {
        console.log(res,"zzzzzz");
        this.page.total = res.data.count;
        this.goodsList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    getUserList() {
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        id:this.id,
        name:this.name
      };
      mallGoodsIndex(params).then((res) => {
        console.log(res,"qqqqqqqqq");
        if(res.data.status==200){
           this.goodsList=res.data.data
            this.page.total = res.data.count;
            this.userList = res.data.data;
            this.$refs.dataTable.setPageInfo({
            total: this.page.total,
            }); 
        }
        
      });
    },
    add(type){
        this.$refs.AddDialog.show(type,{})
    },
    edit(type,row){
      this.$refs.AddDialog.show(type,JSON.parse(JSON.stringify(row)))
    },
    showtable(row){
        this.dialogVisible=true
        this.richText=row.content
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
