<template>
  <div class="AddDialog">
    <el-dialog
      :title="type == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="900px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="goodsForm"
        ref="goodsForm"
        :rules="rules"
        label-width="auto"
      >
        <el-col :span="12">
          <el-form-item label="名称" prop="goods_name" >
            <el-input v-model="goodsForm.goods_name" placeholder="请输入名称" style="width: 280px">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="价格" prop="price">
            <el-input
              v-model="goodsForm.price"
              placeholder="请输入价格"
               style="width: 280px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="简介" prop="brief" >
            <el-input
              v-model="goodsForm.brief"
              placeholder="请输入简介"
              style="width: 280px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="类型" prop="goodsType" >
            <el-select v-model="goodsForm.goodsType" placeholder="请选择商品类型" style="width: 280px">
              <el-option :value="1" label="普通商品"></el-option>
              <el-option :value="2" label="兑换券"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="是否上架" prop="isShow">
            <el-select v-model="goodsForm.isShow" placeholder="请选择是否上架" style="width: 280px">
              <el-option :value="10" label="上架"></el-option>
              <el-option :value="20" label="下架"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="封面图" prop="goods_img">
            <el-upload
              class="avatar-uploader"
              :action="this.$server.api + 'command/ossUpload?filename=file'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" /><i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="商品详情"
            ><file-fu
              v-model="goodsForm.content"
              :isClear="isClear"
              @change="change"
            ></file-fu>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="this.type == 1">
        <el-button @click="close()">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-if="this.type == 2">
        <el-button @click="close()">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mallGoodsAdd ,mallGoodsEdit} from "@/request/api";
import fileFu from "./fileFu.vue";

export default {
  name: "AddDialog",
  components: {
    fileFu,
  },
  data() {
    return {
      isClear: false,
      imageUrl: "",
      type: 1, //1新增，2编辑
      id:"",
      dialogVisible: false,
      token: "",
      goodsForm: {
        goods_name: "",
        brief: "",
        goodsType: "",
        price: "",
        content: "",
        goods_img: "",
        isShow:""
      },
      rules: {
        goods_name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        brief: [{ required: true, message: "请输入简介", trigger: "blur" }],
        price: [
          { required: true, message: "请输入商品价格", trigger: "blur" },
        ],
        goodsType: [{ required: true, message: "请选择商品类型", trigger: "change" }],
        goods_img: [{ required: true, message: "请选择封面图片", trigger: "blur" }],
        // content: [{ required: true, message: "请输入商品详细信息", trigger: "change" }],
        isShow: [{ required: true, message: "请选择是否上架", trigger: "change" }],
      },
    };
  },
  created: function () {
    this.getRolesList(); //获取角色
    this.token = sessionStorage.getItem("token");
  },
  mounted: function () {},
  methods: {
    change(val) {
      console.log(val,'fu');
      this.goodsForm.content = val;
    },
    handleEdit() {
      let oldProcedure = this.$refs["fileFu"].getVal();
    },
    show(type, row) {
      this.type = type;
      this.dialogVisible = true;
      if(type==2) {
        this.id = row.id;
        this.goodsForm.goods_img=row.goods_img
        this.imageUrl = this.goodsForm.goods_img;
        this.goodsForm.goods_name=row.name
        this.goodsForm.price=row.price
        this.goodsForm.goodsType=row.type
        this.goodsForm.content=row.content
        this.goodsForm.brief=row.brief
        this.goodsForm.isShow=row.is_show
      }
    },

    close() {
        this.goodsForm.goods_name= "",
        this.goodsForm.brief= "",
        this.goodsForm.goodsType= "",
        this.goodsForm.price= "",
        this.goodsForm.content= "",
        this.goodsForm.goods_img= "",
        this.goodsForm.isShow=""
        this.imageUrl=""
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.$refs.goodsForm.clearValidate(); //关闭清空校验规则
      });
    },

    handleAvatarSuccess(res, file) {
      let imgurl = res.data;
      this.imageUrl = imgurl;
      this.goodsForm.goods_img = imgurl;
    },
    // beforeAvatarUpload(file) {
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     this.$message.error("上传图片大小不能超过 2MB!");
    //   }
    //   return isLt2M;
    // },

    submitForm() {
      this.$refs.goodsForm.validate(async (valid) => {
        if (valid) {
          if (this.type == 1) {
            let res = await mallGoodsAdd({
              name: this.goodsForm.goods_name,
              brief: this.goodsForm.brief,
              price: this.goodsForm.price,
              type: this.goodsForm.goodsType,
              content: this.goodsForm.content,
              goods_img: this.goodsForm.goods_img,
              token: sessionStorage.getItem("token"),
              is_show:this.goodsForm.isShow
            });

            if (res.status == 200) {
              this.$message.success("新增成功");
              this.$parent.getUserList();
              this.close();
            }
          } else if (this.type == 2) {
            let res = await mallGoodsEdit({
              name: this.goodsForm.goods_name,
              brief: this.goodsForm.brief,
              price: this.goodsForm.price,
              type: this.goodsForm.goodsType,
              content: this.goodsForm.content,
              goods_img: this.goodsForm.goods_img,
              token: sessionStorage.getItem("token"),
              id: this.id,
              is_show:this.goodsForm.isShow
            });
            if (res.status == 200) {
              this.$message.success("编辑成功");
              this.$parent.getUserList();
              this.close();
            }
          } 
        } else {
          return false;
        }
      });
    },
  },
};
</script>

</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.xian {
  margin-left: -75px !important;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
